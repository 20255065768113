import React from 'react';
import styled from '@emotion/styled';

import { colors, respondFrom, breakpoints, css } from '@styles';

const StyledLineAfter = styled.span`
  ${respondFrom(
    breakpoints.lg,
    css`
      display: inline-block;
      position: absolute;
      bottom: -50px;
      left: 50%;
      height: 48px;
      width: 1px;
      background-color: ${colors.green.dark};
      transform: translateY(100%);
    `
  )}
`;

export default () => <StyledLineAfter />;
