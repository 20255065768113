import React, { Component } from 'react';

import * as utils from '@utils';
import Image from 'gatsby-image';
import styled from '@emotion/styled';

import { colors, respondFrom, breakpoints, css, mixins } from '@styles';
import Badge from '@components/common/Badge';
import BadgeRecommended from '@components/common/BadgeRecommended';

import { ProductSimple } from '@content/types/branch';
import ProfitItem from '@components/configurator/tariff/ProfitItem';
import ButtonLink from '@components/common/ButtonLink';

const ProductItemWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  flex-basis: 33%;
  max-width: 510px;
`;

const ProductItem = styled.div`
  background-color: ${colors.ui.whisper};
  width: 100%;
  position: relative;
  /* overflow: hidden; */
  padding: 30px;
  margin-bottom: 20px;
  padding-top: 80px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;

  ${mixins.transitionDefault}

  ${respondFrom(
    breakpoints.lg,
    css`
      padding-left: 60px;
      padding-right: 60px;
      margin-bottom: 20px;
      padding-top: 30px;
    `
  )}

  h2 {
    font-size: 20px;
    font-weight: 100;

    ${respondFrom(
      breakpoints.lg,
      css`
        font-size: 30px;
      `
    )}
  }
`;

const ProfitContainer = styled.div`
  padding: 20px 0;
  font-weight: 300;
  font-size: 14px;
  flex-grow: 1;

  p {
    font-size: 14px !important;
  }

  strong {
    font-weight: 700;
  }
`;

const ProductImage = styled(Image)`
  text-align: center;
  position: absolute !important;
  top: 20px;
  right: 20px;
  height: 100px !important;
  width: 130px !important;

  ${respondFrom(
    breakpoints.lg,
    css`
      position: relative !important;
      height: 250px !important;
      width: calc(100% + 60px) !important;
      margin: 20px 0;
      margin-left: -30px;
      top: 0;
      right: 0;
    `
  )}
`;

const StyledButtonLink = styled(ButtonLink)`
  align-self: flex-start;
`;

const BadgeRecommendedStyled = styled.div`
  position: absolute;
  top: -35px;
  left: 0;
`;

interface Props {
  product: ProductSimple;
  branchSlug: string;
  langcode: string;
  urls: Array<string>;
}

class BranchDevicesItem extends Component<Props> {
  render() {
    const { product, branchSlug, langcode, urls } = this.props;

    const getButtonText = () => {
      if (product.isRecommended) {
        return product.buttonTextRecommended ? product.buttonTextRecommended : 'Kup dedykowane rozwiązanie';
      }

      return product.buttonText ? product.buttonText : 'Kup teraz';
    }

    return (
      <ProductItemWrapper>
        <ProductItem className={``}>
          {product !== undefined ? (
            <>
              {product.isRecommended ? (
                <BadgeRecommendedStyled>
                  <BadgeRecommended text="Rekomendowany" />
                </BadgeRecommendedStyled>
              ) : (
                false
              )}

              <h2>{product.name}</h2>
              {product.image && (
                <ProductImage
                  {...product.image.childImageSharp}
                  imgStyle={{ objectFit: 'contain' }}
                />
              )}

              <ProfitContainer>
                {product.profits.map((profit, index) => (
                  <ProfitItem {...profit} key={index} />
                ))}
              </ProfitContainer>

              <StyledButtonLink
                to={utils.langLink(langcode, `${urls[21]}=${branchSlug}`)}
                icon="arrow-right"
                className="no-parent-hovering"
              >

                {getButtonText()}

              </StyledButtonLink>
            </>
          ) : (
            false
          )}
        </ProductItem>
      </ProductItemWrapper>
    );
  }
}

export default BranchDevicesItem;
