import React from 'react';
import styled from '@emotion/styled';

import * as utils from '@utils';
import Container from '@components/layout/Container';
import { BranchSectionDevicesProps } from '@content/types/branch';
import BranchDevicesItem from './BranchDevicesItem';
import Title2 from '@components/common/Title2';
import { respondFrom, breakpoints, css } from '@styles';

const Wrapper = styled.div``;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 15px;

  ${respondFrom(
    breakpoints.xl,
    css`
      justify-items: center;
    `
  )}

  &.mode-narrow {
    padding: 0 30px;
    ${respondFrom(
      breakpoints.lg,
      css`
        padding: 0 40px;
      `
    )}
  }
`;

const StyledTitle2 = styled(Title2)`
  h2 {
    max-width: none;
  }
`;

interface Props extends BranchSectionDevicesProps {
  branchSlug: string;
  langcode: string;
  urls: Array<string>;
}

const BranchDevices = (props: Props) => (
  <Container>
    <StyledTitle2>{utils.SafeHtml(props.headline || '')}</StyledTitle2>
    <Wrapper>
      <List>
        {props.products.map((product, i) => (
          <BranchDevicesItem
            product={product}
            key={i}
            branchSlug={props.branchSlug}
            langcode={props.langcode}
            urls={props.urls}
          />
        ))}
      </List>
    </Wrapper>
  </Container>
);

export default BranchDevices;
