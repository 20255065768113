import React from 'react';
import styled from '@emotion/styled';
import { respondFrom, breakpoints, css } from '@styles';
import Container from '@components/layout/Container';
import Tariffs from '@components/common/Tariffs';
import { BranchSectionTariffsProps } from '@content/types/branch';

const Wrapper = styled.div`
  margin-bottom: 60px;

  ${respondFrom(
    breakpoints.lg,
    css`
      margin-bottom: 150px;
    `
  )}
`;

interface Props extends BranchSectionTariffsProps {
  branchSlug: string;
  title: string;
  subtitle?: string;
  buttonLabel: string;
  tariffs: Array<any>;
  pricePeriod: string;
  compareButtonLabel: string;
  langcode: string;
  urls: Array<string>;
}

const BranchesTarrifs = (props: Props) => {
  return (
    <Wrapper>
      <Container>
        <Tariffs
          title={props.title}
          subtitle={props.subtitle}
          buttonLabel={props.buttonLabel}
          tariffs={props.tariffs}
          pricePeriod={props.pricePeriod}
          backgroundDark={true}
          langcode={props.langcode}
          urls={props.urls}
          hidePrice={false}
        />
      </Container>
    </Wrapper>
  );
};

export default BranchesTarrifs;
