import React from 'react';
import styled from '@emotion/styled';
import { navigate } from '@utils';

import {
  colors,
  dimensions,
  respondFrom,
  respondTo,
  breakpoints,
  css,
  mixins,
  fluidRange,
} from '@styles';
import { ImageSharp } from '@content/types/general';
import OverlaySectionImage from '@components/common/OverlaySectionImage';
import LineAfterSection from '@components/common/LineAfterSection';

interface StylesProps {
  imageOnRight?: boolean;
}

const StyledOverlappingSection = styled.div<StylesProps>`
  position: relative;
  width: 100%;

  ${respondFrom(
    breakpoints.lg,
    css`
      display: flex;
      flex-direction: row;

      padding: 0 ${dimensions.boxesSpacing / 2}px;
      margin-bottom: 100px;

      &.overlapping-reverse {
        flex-flow: row-reverse;
      }
    `
  )}
`;

const StyledImageContainer = styled.div`
  overflow: visible;
  position: relative;

  .gatsby-image-wrapper {
    max-width: 100% !important;
    margin: auto;
  }

  ${respondTo(
    breakpoints.lg,
    css`
      ${mixins.negateContainerPadding}
      text-align: center;

      .gatsby-image-wrapper {
        max-height: 50vh;
      }
    `
  )}

  ${respondFrom(
    breakpoints.lg,
    css`
      /* flex-basis: 43.5%; */
      width: 43.5%;

      .gatsby-image-wrapper {
        overflow: visible !important;
        right: -15%;

        .overlapping-reverse & {
          left: -15%;
          right: auto;
        }

        img {
          position: absolute;
          left: auto !important;
          max-width: none;
          width: auto !important;
          right: 0;

          .overlapping-reverse & {
            right: auto !important;
            left: 0 !important;
          }
        }
      }
    `
  )}
`;

const StyledContentContainerOuter = styled.div`
  ${respondTo(
    breakpoints.lg,
    css`
      ${mixins.negateContainerPadding}
    `
  )}

  ${respondFrom(
    breakpoints.lg,
    css`
      flex-basis: 56.5%;
      align-self: center;
      height: auto;
      margin: auto;
    `
  )}
`;

const StyledContentContainer = styled.div`
  position: relative;
  background-color: ${colors.ui.whisper};
  overflow: hidden;
  height: 100%;
  padding: 0 ${dimensions.containerPadding}px;
  ${fluidRange('paddingTop', '30px', '80px')}
  ${fluidRange('paddingBottom', '30px', '80px')}

  display: flex;
  flex-direction: column;
  justify-content: center;

  &.hoverable {
    /* ${mixins.borderOnHover} */
  }

  ${respondFrom(
    breakpoints.lg,
    css`
      padding-left: 100px;
      padding-right: 100px;
    `
  )}


  h3 {
    font-size: 30px;
    margin-bottom: 0.9em;
  }

  p {
    line-height: 1.5;
    margin-bottom: 1.9em;
  }

  &.slim {
    ${fluidRange('paddingTop', '30px', '50px')}
    ${fluidRange('paddingBottom', '30px', '60px')}

    &.hasSecondContent {
      margin-top: -20px;
    }

    p { 
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
`;

const StyledContent2Container = styled(StyledContentContainer)`
  background: none;
  max-width: 700px;
  margin: 0 auto;
  ${fluidRange('paddingTop', '20px', '40px')}
  ${fluidRange('paddingBottom', '20px', '40px')}

  ${respondTo(
    breakpoints.lg,
    css`
      margin-top: 30px;
    `
  )}
  ${respondFrom(
    breakpoints.lg,
    css`
      padding-bottom: 0;
    `
  )}
`;

const StyledOverlaySectionImage = styled(OverlaySectionImage)`
  > .gatsby-image-wrapper {
    left: 50%;
  }
`;

const BonusImageContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  ${respondFrom(
    breakpoints.lg,
    css`
      right: 15%;
    `
  )}
`;

interface SectionOverlappingProps extends StylesProps {
  imageContent?: React.ReactElement | null;
  bonusImage?: React.ReactElement | null;
  content?: React.ReactElement | null;
  contentNoBg?: React.ReactElement | null;
  overlayImage?: ImageSharp;
  contentTo?: string | null;
  lineAfter?: boolean;
  slimContent?: boolean;
  overlayImageAlt?: string;

  className?: string;
}

export default (props: SectionOverlappingProps) => (
  <StyledOverlappingSection
    className={`
      ${props.imageOnRight ? 'overlapping-reverse' : ''}
      ${props.className || ''}   
  `}
  >
    <StyledImageContainer>
      {props.imageContent}
      {props.bonusImage && <BonusImageContainer>{props.bonusImage}</BonusImageContainer>}
    </StyledImageContainer>

    <StyledContentContainerOuter>
      <StyledContentContainer
        className={`${props.contentTo ? 'hoverable' : ''}
          ${props.contentNoBg || props.slimContent ? 'slim' : ''} 
          ${props.contentNoBg ? 'hasSecondContent' : ''} 
        `}
        onClick={() => (props.contentTo ? navigate(props.contentTo) : null)}
      >
        {props.content}
      </StyledContentContainer>

      {!!props.contentNoBg && (
        <StyledContent2Container>{props.contentNoBg}</StyledContent2Container>
      )}

      {props.lineAfter && <LineAfterSection />}
    </StyledContentContainerOuter>

    {props.overlayImage && (
      <StyledOverlaySectionImage image={props.overlayImage} alt={props.overlayImageAlt} />
    )}
  </StyledOverlappingSection>
);
