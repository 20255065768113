import React from 'react';
import styled from '@emotion/styled';
import Image from 'gatsby-image';

import * as utils from '@utils';
import SectionOverlapping from '@components/common/SectionOverlapping';
import Container from '@components/layout/Container';
import ButtonLink from '@components/common/ButtonLink';
import Badge from '@components/common/Badge';
import Title2 from '@components/common/Title2';
import { BranchSectionRecommendationProps } from '@content/types/branch';

const StyledContent = styled.div``;

const StyledButtonLink = styled(ButtonLink)`
  align-self: flex-start;
`;

interface Props extends BranchSectionRecommendationProps {
  slug: string;
  langcode: string;
  urls: Array<string>;
}

export default (props: Props) => (
  <Container>
    <Title2>{utils.SafeHtml(props.headline)}</Title2>
    <SectionOverlapping
      imageContent={props.image && <Image {...props.image.childImageSharp} />}
      imageOnRight={true}
      content={
        <StyledContent>
          {props.isOnline && <Badge toLeft={true} />}
          <div>{utils.SafeHtml(props.paragraph)}</div>

          <StyledButtonLink
            to={utils.langLink(props.langcode, `${props.urls[21]}=${props.slug}`)}
            icon="arrow-right"
            className="no-parent-hovering"
          >
            {props.buttonText || 'Kup dedykowane rozwiązanie'}
          </StyledButtonLink>
        </StyledContent>
      }
    />
  </Container>
);
