import React from 'react';
import styled from '@emotion/styled';
import Image from 'gatsby-image';

import * as utils from '@utils';
import { respondFrom, breakpoints, css, colors } from '@styles';
import SectionOverlapping from '@components/common/SectionOverlapping';
import Container from '@components/layout/Container';
import { BranchSectionTestimonialsProps } from '@content/types/branch';
import Title2 from '@components/common/Title2';

import quoteIcon from '@assets/svg/quote-icon.svg';

const StyledContent = styled.div`
  font-size: 24px;
  line-height: 1.42;
  font-weight: 200;
  color: ${colors.text.article};
  position: relative;

  strong {
    font-weight: 700;
  }

  p {
    &:first-of-type {
      font-style: italic;
    }
  }
`;

const StyledSectionOverlapping = styled(SectionOverlapping)`
  margin-bottom: 50px;

  ${respondFrom(
    breakpoints.lg,
    css`
      margin-bottom: 150px;
    `
  )}
`;

const HeadlineLogo = styled.div`
  display: inline-block;
  margin-left: 20px;
  img {
    height: 65px !important;
    width: auto !important;
  }
`;
const StyledQuoteIcon = styled.img`
  position: absolute;
  top: 10px;
  left: 0;
  transform: translate(-150%, -100%);
`;

interface Props extends BranchSectionTestimonialsProps {}

export default (props: Props) => (
  <Container>
    {!!props.headline && (
      <>
        <Title2>{utils.SafeHtml(props.headline)}</Title2>
        {!!props.headlineLogo && (
          <HeadlineLogo>
            <Image {...props.headlineLogo.childImageSharp} />
          </HeadlineLogo>
        )}
      </>
    )}
    <StyledSectionOverlapping
      imageOnRight={true}
      slimContent={true}
      imageContent={props.image && <Image {...props.image.childImageSharp} />}
      content={
        <StyledContent>
          {utils.SafeHtml(props.paragraph)}
          <StyledQuoteIcon src={quoteIcon} />
        </StyledContent>
      }
    />
  </Container>
);
