import React from 'react';
import { Redirect } from '@reach/router';

import useBranchesQuery from '@graphql/queries/BranchesQuery';
import Page from '@components/layout/Page';
import BranchDetails from '@containers/branches/branch';
import { PageContext } from '@content/types/pageContext';
import MetaPage from '@components/common/MetaPage';
import DataLayer from '@components/layout/DataLayer';
import { LocationDependent } from '@content/types/general';
import * as utils from '@utils';

interface Props extends LocationDependent {
  pageContext: PageContext;
}

export default (props: Props) => {
  const branches = useBranchesQuery();
  const branch = branches.allBranches[props.pageContext.langcode].find(
    b => b.slug === props.pageContext.slug
  );

  return branch ? (
    <Page>
      <MetaPage title={branch.seo.title} description={branch.seo.description} image={branch.seo.image} />
      <DataLayer location={props.location} />
      <BranchDetails
        {...branch}
        langcode={props.pageContext.langcode}
        urls={props.pageContext.allUrls}
      />
    </Page>
  ) : (
    <Redirect to={utils.langLink(props.pageContext.langcode, '/404')} />
  );
};
