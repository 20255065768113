import React from 'react';
import styled from '@emotion/styled';
import Image from 'gatsby-image';

import * as utils from '@utils';
import { respondFrom, breakpoints, css } from '@styles';
import { BranchSectionFeaturesProps } from '@content/types/branch';
import SectionOverlapping from '@components/common/SectionOverlapping';
import Container from '@components/layout/Container';
import Title2 from '@components/common/Title2';
import ProductsFourInRow from '@components/products/ProductsFourInRow';
import ButtonLink from '@components/common/ButtonLink';

const StyledContent = styled.div``;

const StyledSectionOverlapping = styled(SectionOverlapping)`
  ${respondFrom(
    breakpoints.lg,
    css`
      margin-bottom: 50px;
    `
  )}
`;

const StepsWrapper = styled.div`
  margin-bottom: 70px;
`;

const StyledButtonLink = styled(ButtonLink)`
  align-self: flex-start;
`;

interface Props extends BranchSectionFeaturesProps {
  slug: string;
  langcode: string;
  urls: Array<string>;
}

export default (props: Props) => (
  <Container>
    <Title2>{utils.SafeHtml(props.headline)}</Title2>
    <StyledSectionOverlapping
      imageContent={props.image && <Image {...props.image.childImageSharp} />}
      content={
        <StyledContent>
          {utils.SafeHtml(props.paragraph)}

          <StyledButtonLink
            to={utils.langLink(props.langcode, `${props.urls[21]}=${props.slug}`)}
            icon="arrow-right"
            className="no-parent-hovering"
          >
            {props.buttonText || 'Kup dedykowane rozwiązanie'}
          </StyledButtonLink>
        </StyledContent>
      }
    />

    {!!props.features && !!props.features.length && (
      <StepsWrapper>
        <ProductsFourInRow
          data={props.features}
          arrowLines={false}
          noLines={
            props.features &&
            props.features[0] &&
            !!props.features[0].image &&
            props.features[0].image.substr(-3).toLowerCase() !== 'svg'
          }
        />
      </StepsWrapper>
    )}
  </Container>
);
