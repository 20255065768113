import React from 'react';

import * as utils from '@utils';
import Hero from '@components/common/Hero';
import { BranchSectionHeroProps } from '@content/types/branch';

interface Props extends BranchSectionHeroProps {
  slug: string;
  langcode: string;
  urls: Array<string>;
}

export default (props: Props) => {
  return (
    <Hero
      size="medium"
      image={props.image ? props.image.childImageSharp : undefined}
      imageMobile={props.imageMobile ? props.imageMobile.childImageSharp : undefined}
      title={utils.SafeHtml(props.title)}
      paragraph={utils.SafeHtml(props.paragraph)}
      buttonText={props.buttonText || 'Kup dedykowane rozwiązanie'}
      buttonRoute={utils.langLink(props.langcode, `${props.urls[21]}=${props.slug}`)}
      overlayImage={props.deviceImage || undefined}
    ></Hero>
  );
};
