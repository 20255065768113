import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import actions from '@store/actions';
import BranchDetails, { BranchDetailsProps } from '@components/branches/BranchDetails';
import { RootState } from '@store/types';

const mapStateToProps = (state: RootState) => ({
  configurator: state.configurator,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  configuratorActions: bindActionCreators(actions.configurator, dispatch),
});

interface DBranchDetailsProps extends BranchDetailsProps {}

const DBranchDetails: React.FC<DBranchDetailsProps> = 
  (props: DBranchDetailsProps) => <BranchDetails {...props}  />;

const BranchDetailsConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(DBranchDetails);

export default BranchDetailsConnected;
