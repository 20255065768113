/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Component } from 'react';
import styled from '@emotion/styled';
import { colors, dimensions } from '@styles';
import { Funding, ConfiguratorBasket } from '@store/types';
import IconTick from '@assets/svg/feature-incl-icon.svg';
import IconClose from '@assets/svg/feature-notincl-icon.svg';
import * as utils from '@utils';

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  > div {
    margin-bottom: 0.64rem;

    h5 {
      font-size: ${dimensions.fontSize.small}px;
      font-weight: 300;
      margin: 0;
    }

    p {
      font-size: 10px;
      margin: 0;
    }
  }

  img {
    margin: 2px 10px 0 0;
    flex: 1;
    max-width: 13px;
    height: 13px;
    width: 13px;
  }

  &.deactive {
    p,
    h5 {
      color: ${colors.ui.light};
    }
  }
`;

interface ProfitItemProps {
  reference: any;
  active: boolean;
  name: string;
  fundings: Funding[];
  basket: ConfiguratorBasket;
  langcode: string;
}

class ProfitItem extends Component<ProfitItemProps> {
  getReferenceText = () => {
    let text;

    this.props.reference.forEach(ref => {
      const f = utils.transformFundingsSelectionsToSend(
        this.props.basket.fundings,
        this.props.fundings[this.props.langcode]
      );
      if (ref.tariffsId.find(id => f.length && id === f[0].id) !== undefined) {
        text = ref.text;
      } else {
        text = this.props.name;
      }
    });

    return text;
  };

  render() {
    const { active, name, reference } = this.props;

    return (
      <Wrapper className={active === true ? 'active' : 'deactive'}>
        {active === true ? (
          <img src={IconTick} alt="icon tick" />
        ) : (
          <img src={IconClose} alt="icon tick" />
        )}
        {!reference || reference.length === 0
          ? utils.SafeHtml(name)
          : utils.SafeHtml(this.getReferenceText())}
      </Wrapper>
    );
  }
}

export default ProfitItem;
