import React from 'react';

import Page from '@components/layout/Page';
import Container from '@components/layout/Container';
import { PageContext } from '@content/types/pageContext';
import {
  BranchSectionHeroProps,
  Branch,
  BranchSectionWhatisProps,
  BranchSectionRecommendationProps,
  BranchSectionFeaturesProps,
  BranchSectionBulletsProps,
  BranchSectionDevicesProps,
  BranchSectionTariffsProps,
  BranchSectionTestimonialsProps,
  BranchSectionDiscountsProps,
  BranchSectionSavingUpProps,
} from '@content/types/branch';

import BranchHero from './sections/BranchHero';
import BranchWhatIsIpos from './sections/BranchWhatIsIpos';
import BranchRecommendation from './sections/BranchRecommendation';
import BranchFeatures from './sections/BranchFeatures';
import BranchFeatures2 from './sections/BranchFeatures2';
import BranchBullets from './sections/BranchBullets';
import BranchDevices from './sections/BranchDevices';
import BranchTariffs from './sections/BranchTariffs';
import BannerSavings from '@components/common/BannerSavings';
import DiscountBoxes from '@components/common/DiscountBoxes';
import BranchTestimonial from './sections/BranchTestimonial';

export interface BranchDetailsProps extends Branch {
  pageContext: PageContext;
  langcode: string;
  urls: Array<string>;
}

export default (props: BranchDetailsProps) => {
  return (
    <Page>
      {props.sections.map((section, s) => {
        if (section.type === 'hero') {
          return (
            <BranchHero
              {...(section as BranchSectionHeroProps)}
              slug={props.slug}
              key={s}
              langcode={props.langcode}
              urls={props.urls}
            />
          );
        } else if (section.type === 'what_is_ipos') {
          return <BranchWhatIsIpos {...(section as BranchSectionWhatisProps)} key={s} />;
        } else if (section.type === 'recommendation') {
          return (
            <BranchRecommendation
              {...(section as BranchSectionRecommendationProps)}
              slug={props.slug}
              key={s}
              langcode={props.langcode}
              urls={props.urls}
            />
          );
        } else if (section.type === 'features') {
          return <BranchFeatures {...(section as BranchSectionFeaturesProps)} key={s} />;
        } else if (section.type === 'features2') {
          return (
            <BranchFeatures2
              {...(section as BranchSectionFeaturesProps)}
              slug={props.slug}
              key={s}
              langcode={props.langcode}
              urls={props.urls}
            />
          );
        } else if (section.type === 'bullets') {
          return (
            <BranchBullets
              {...(section as BranchSectionBulletsProps)}
              key={s}
              noLastDot={true}
              circles={[{ position: 0, rotate: 65, size: 'medium' }]}
              langcode={props.langcode}
              urls={props.urls}
            />
          );
        } else if (section.type === 'products') {
          return (
            <BranchDevices
              {...(section as BranchSectionDevicesProps)}
              key={s}
              branchSlug={props.slug}
              langcode={props.langcode}
              urls={props.urls}
            />
          );
        } else if (section.type === 'tariffs') {
          return (
            <BranchTariffs
              {...(section as BranchSectionTariffsProps)}
              key={s}
              branchSlug={props.slug}
              langcode={props.langcode}
              urls={props.urls}
            />
          );
        } else if (section.type === 'testimonial') {
          return <BranchTestimonial {...(section as BranchSectionTestimonialsProps)} key={s} />;
        } else if (section.type === 'savingUp') {
          return (
            <BannerSavings
              {...(section as BranchSectionSavingUpProps)}
              key={s}
              langcode={props.langcode}
              urls={props.urls}
            />
          );
        }
      })}

      <Container>
        {props.sections.map((section, index) => {
          if (section.type === 'discounts') {
            return (
              <DiscountBoxes
                key={index}
                {...(section as BranchSectionDiscountsProps)}
                langcode={props.langcode}
                urls={props.urls}
              />
            );
          }
        })}
        {/* <ProductsBranches /> */}
      </Container>
    </Page>
  );
};
